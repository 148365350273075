<template>
    <div class="warp" style="background:#000;" v-loading="Loading" element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="main">
            <div>
                <el-row :gutter="20">
                    <el-col :span="18" :class="zpan_ys.zp0">
                        <div class="lefttitle">
                            <p style="position: relative;top:41px;text-align: center;">活动时间 {{ info.on_off }}</p>
                        </div>
                        <div :class="zpan_ys.zp2">
                            <div  :class="zpan_ys.zp1">
                                <ul class="itmeslis">
                                    <li  class="itmes"  v-for="v, i in info.pool.jl" :class="generateClassName(i)" :key="i">
                                        <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content">
                                                <div style="color: #babd9a;width: 190px;">
                                                    <p style="text-align:center;margin-bottom:6px;">{{ v.name }}</p>
                                                    <p style="margin-bottom:6px;" v-for="va, indx in v.code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                                                </div>
                                            </div>
                                            <div :class="{'wchde':v.state == 0}" ></div>
                                        </el-tooltip> 
                                        
                                        <el-tooltip class="item" effect="dark" content="" placement="top">
                                            <div slot="content">
                                                <div style="color: #babd9a;width: 190px;">
                                                    <p style="text-align:center;margin-bottom:6px;">{{ v.name }}</p>
                                                    <p style="margin-bottom:6px;" v-for="va, indx in v.code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                                                </div>
                                            </div>
                                            <img :src="v.ioc" />
                                        </el-tooltip> 
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </el-col>
                    <el-col :span="6" class="rightico">
                        <el-row :gutter="20">
                            <!-- 能量 -->
                            <el-col :span="24">
                                <div class="qzzs">
                                    <el-progress type="circle" :percentage="info.energy" :width="87"></el-progress>
                                </div>
                                <!-- 玩法说明 -->
                                <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="left">
                                    <div slot="content" >
                                        <div>
                                            <img style="width:486px;" src="../../../public/static/plug/engine/static/images/modelsm.png" />
                                        </div>
                                    </div>
                                    <!-- <span style="position: absolute;cursor:pointer;" class="el-icon-question"></span> -->
                                </el-tooltip>
                            </el-col>


                            <!-- 使用 -->
                            <el-col :span="24">
                                <div class="rightjl1">
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <p style="position: absolute;z-index: 1000;left: 2px;top: -6px;text-align: right;width: 37px;">{{ info.config.qualification }}</p>
                                            <img src="../../../public/static/plug/engine/static/images/12943.png" style="width: 34px;height: 34px;position: relative;top: -2px;left: -5px;">  
                                        </el-col>
                                        <el-col :span="12" >
                                            <div class="rightbuy" @click="BuyDisplay = true"></div>
                                            <div class="rightsgzs" @click="StartCj" v-if="Startlog == false"></div>
                                            <div class="rightsgzs_jz" v-else></div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>


                            <!-- 启动 -->
                            <el-col :span="24">
                                <div class="rightjl2">
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <el-tooltip class="item" effect="dark" content="" placement="left">
                                                <div slot="content">
                                                    <div style="width: 190px;color: #ef9b9b;">
                                                        <p style="text-align: center;">{{ info.config.lb.name }}</p>
                                                        <p v-for="(v, i) in info.config.lb.code" :key="i">{{ v.name }}x{{ v.num }}</p>
                                                    </div>
                                                </div>
                                                <img :src="info.config.lb.ioc" style="width: 34px;height: 34px;position: relative;top: -2px;left: -5px;">
                                            </el-tooltip>
                                        </el-col>
                                        <el-col :span="12" >
                                            <div v-if="info.config.lb_yj == 1" style="margin-top: 6px;margin-left: -5px;">已领取</div>
                                            <div v-else-if="info.config.lb_yj == 0 && info.pool.lq_num == 20" class="rightqd" @click="PoolLq"></div>
                                            <div v-else class="rightqd_jz"></div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>

                            <!-- 初始化 -->
                            <el-col :span="24">
                                <div class="rightjl3">
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <span style="position: relative;top: 6px;">{{ info.config.reset_num_yj }} / {{ info.config.reset_num }}</span>
                                        </el-col>
                                        <el-col :span="12" >
                                            <div v-if="info.config.reset_num_yj >= info.config.reset_num" class="rightcz_jz"></div>
                                            <div v-else-if="info.config.reset_num_yj < info.config.reset_num && info.pool.lq_num == 20" class="rightcz" @click="EngineInitia"></div>
                                            <div v-else class="rightcz_jz"></div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>

                            <!-- 登录 -->
                            <el-col :span="24">
                                <div class="rightjl4">
                                    <el-row :gutter="20">
                                        <el-col :span="24">
                                            <p style="text-align: center;">登录：<span>{{ info.charac_name }}</span> </p>
                                            <p style="text-align: center;color:#ef0101;cursor:pointer;" @click="LoginDisplay = true"><span>《绑定》</span></p>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            
        </div>
        <div class="alert">
            <!-- 弹出框 -->
            <div class="alert_bg" v-if="MessageDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                    <div class="alert_com">
                        <span>{{ MessageMsg }}</span>
                        <div class="btn"
                            @click="CloseAlert">关闭</div>
                    </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <div class="alert_bg" v-if="SuccessDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                    <div class="alert_com">
                        <span>{{ MessageMsg }}</span>
                        <div class="btn"
                            @click="SuccessAlert">关闭</div>
                    </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 登陆框 -->
            <div class="alert_bg" v-if="LoginDisplay">
                <div class="alert_main"
                    style="margin-top: 166px; width: 350px">
                    <div class="alert_title"><span>绑定|切换</span></div>
                    <div class="alert_con">
                    <div class="alert_com">
                        <p style="margin-bottom: 6px; display: flex">
                        <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                                v-model="login_info.username"
                                class="service__input" />
                        </p>
                        <p style="margin-bottom: 6px; display: flex">
                        <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                                v-model="login_info.password"
                                class="service__input" />
                        </p>
                        <p style="margin-bottom: 6px; display: flex">
                        <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                        <select v-model="login_info.role"
                                style="width: 174px">
                            <option v-for="(itmec, i) in role_data"
                                    :key="i"
                                    :value="itmec.charac_no">
                            {{ itmec.charac_name }}
                            </option>
                        </select>
                        <span style="background: #7478e4;padding: 3px 10px;margin-left: 2px;border-radius: 4%;" @click="getRole">获取</span>
                        </p>
                        <div>
                        <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0px 0px">{{
                                                    LoginError
                                                    }}</span></span>
                        </div>
                        <div style="text-align: center; padding: 10px 0px">
                        <div class="btn"
                            @click="login"
                            style="margin: 0px 0px; display: inline-block">
                            绑定
                        </div>
                        <div class="btn"
                            @click="LoginDisplay = false"
                            style="margin: 0px 0px; display: inline-block">
                            取消
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>

            <div class="alert_bg pop-buy" v-if="BuyDisplay">
                <div class="alert_main">
                <i style="position: relative;left: 143px;top: 20px;z-index: 20;font-size: 18px;color: #bc9b9b;"  @click="BuyDisplay = false">x</i>
                <div class="alert_title"><span>资格获得</span></div>
                <div class="alert_con">
                    <div class="alert_com">
                    <div>
                        <div style="border: 1px dashed #444; position: relative">
                        <div style="width: 93%;display: inline-block;float: left;text-align: left;padding: 10px 10px;color: #ff7878;">
                            <p style="margin-bottom: 4px" v-if="info.config.source == 3">
                                <span>请通过商城购买[{{info.config.opportunity_name}}]即可获得资格</span>
                            </p>
                            <p style="margin-bottom: 4px" v-if="info.config.source == 1 || info.config.source == 2">
                                <span style="display: inline-block;padding: 0px;">每购买一次资格需要花费{{info.config.opportunity}}<span v-if="info.config.source == 1" style="display: inline-block;padding: 0px;">点券</span><span v-else-if="info.config.source == 2" style="display: inline-block;padding: 0px;">代币券</span>，请输入需要购买次数</span>
                            </p>
                            <p style="margin-bottom: 4px" v-if="info.config.source == 4">
                                <span style="display: inline-block;padding: 0px;" @click="CloudDiskCopy(info.config.pay_url)">抽奖币请前往《点击复制购买地址》购买完成把CDK兑换码填写到下方兑换</span>
                            </p>
                            <input type="text"
                                v-if="info.config.source == 1 || info.config.source == 2"
                                v-model="buy_num"
                                max="100"
                                placeholder="输入需要购买次数" />
                            <input type="text"
                                v-if="info.config.source == 4"
                                v-model="cdk"
                                placeholder="请输入兑换码" />
                        </div>
                        <div v-if="info.config.source == 1 || info.config.source == 2" style="display: inline-block;float: right;position: absolute;top: 40px;right: 14px;">
                            <a class="btn cera"
                            href="javascript:;" @click="Buy">&nbsp;购买</a>
                        </div>
                        <div v-if="info.config.source == 4" style="display: inline-block;float: right;position: absolute;top: 40px;right: 14px;">
                            <a class="btn cera"
                            href="javascript:;" @click="Buy">&nbsp;兑换</a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="alert_bottom"><span></span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
export default {
    name: "Engine",
    data () {
        return {
            MessageDisplay: false, //弹出框显示
            SuccessDisplay: false,//成功弹出框
            LoginDisplay: false, //登陆弹出框
            LoginError: '',
            MessageMsg: '', //弹出框消息
            Loading: false,
            BuyDisplay:false,
            Startlog:false,
            info: {
                m_id: 0, //账号uid
                charac_no: 0, //角色ID
                charac_name: '', //角色名称
                on_off:'', //活动时间
                energy:0,
                config:{
                    source:1,
                    opportunity:0,
                    opportunity_name:"",
                    qualification:0,
                    pay_url:'',
                    reset_num:0,
                    reset_num_yj:0,
                    lb:{
                        name:'',
                        ioc:'',
                        code:[],
                    },
                    lb_yj:0,
                },
                pool:{
                    lq_name:0,
                    jl:[],
                }
            },
            login_info: {
                username: '',
                password: '',
                role: '',
            },
            role_data: [],
            zpan_ys:{
                zp0:'leftico',
                zp1:'gdzhuk0',
                zp2:'zhojxg0',
            },
            buy_num:1,
            cdk:'',
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        //登陆
        login () {
            if (this.Loading == true) {
            return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/PlugLogin",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                username: this.login_info.username,
                password: this.login_info.password,
                role: this.login_info.role,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                this.LoginDisplay = false;
                this.getInfo();
                } else {
                this.LoginError = response.data.msg;
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
            return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/getRole",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                username: this.login_info.username,
                password: this.login_info.password,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                if (response.data.code == 200) {
                this.LoginError = "";
                this.role_data = response.data.info;
                } else {
                this.LoginError = response.data.msg;
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        //获取基本配置信息
        getInfo () {
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/engine/getEngineInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.info = response.data.info
                    } else {
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //启动抽奖
        StartCj(){
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/engine/EngineLuckyDraw",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                this.BuyDisplay = false;
                if (response.data.code == 200) {
                    this.Startlog = true
                    //去启动转盘
                    this.StartZPan(1,response.data.msg);
                    //this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
            
        },
        //启动转盘
        StartZPan(num,msg){ 
            var that = this;
            setTimeout(function ()  {
                if(num <= 13){
                    that.zpan_ys.zp2 = 'zhojxg'+num;
                    console.log(num);
                    if(num == 8 || num == 9 || num == 10 || num == 11){
                        that.zpan_ys.zp1 = 'gdzhuk0';
                        that.zpan_ys.zp0 = 'leftico'+num;
                    }else{
                        that.zpan_ys.zp0 = 'leftico';
                        that.zpan_ys.zp1 = 'gdzhuk'+num;
                    }
                    that.StartZPan(num+1,msg);
                }else{
                    that.StartReset(msg);
                }
            },150);
        },
        //转盘重置
        StartReset(msg){
            this.zpan_ys.zp0 = 'leftico';
            this.zpan_ys.zp1 = 'gdzhuk0';
            this.zpan_ys.zp2 = 'zhojxg0';
            this.Startlog = false
            this.PopMessage(true, msg);
           
        }, 
        generateClassName (index) {
            // 调用方法，动态生成index
            return `itmes${index+1}`
        },
        Buy(){
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/engine/EngineBuy",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
                num: this.buy_num,
                cdk:this.cdk,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                this.BuyDisplay = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        PoolLq(){
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/engine/EnginePoolLq",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                this.BuyDisplay = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
        EngineInitia(){
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
            // 默认请求方式为get
            method: "post",
            url: Vue.prototype.config.webplugurl + "/engine/EngineInitia",
            // 传递参数
            data: {
                key: this.$route.query.key,
                keyid:this.$route.query.id,
            },
            responseType: "json",
            })
            .then((response) => {
                this.Loading = false;
                this.BuyDisplay = false;
                if (response.data.code == 200) {
                    this.PopMessage(true, response.data.msg);
                } else {
                    this.PopMessage(false, response.data.msg);
                }
            })
            .catch((error) => {
                this.Loading = false;
                // 请求失败，
                console.log(error);
            });
        },
         //复制
        CloudDiskCopy (vars) {
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value = vars
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            this.BuyDisplay = false;
            this.PopMessage(false, '复制成功');
            //移除input标签
            document.body.removeChild(input)
    
        },
        //兑换
        CdkExchange(){

        },
        //弹出框状态
        PopMessage (type, msg) {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.MessageMsg = msg;
            if (type == false) {
            this.MessageDisplay = true;
            } else {
            this.SuccessDisplay = true;
            }
        },
        SuccessAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.getInfo();
        },
        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
        },
    },
};
</script>



<style scoped>
@import "../../../public/static/plug/engine/static/css/game.css";
</style>

<style>
#root,
body,
html {
    min-width: 0px;
}
</style>
<style lang="scss" scoped>
 /deep/ .el-row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }



    /deep/ .el-progress__text {
      top: 66% !important;
    }

    /deep/ .el-tooltip__popper {
        top: 2px !important;
        left: 637px !important;
    }

    /deep/ .el-tooltip__popper.is-dark {
        background: #303133c2 !important;
    }

  

</style>

